export const productColumnData = [
  {
    name: "S.No",
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Name",
    selector: "name",
    width: 600 + "px",
  },
  {
    name: "Brand",
    selector: "brand",
    minWidth: 200 + "px",
  },

  {
    name: "Category",
    selector: "category",
    width: 150 + "px",
  },
  {
    name: "Actions",
    selector: "actions",
    width: 180 + "px",
  },
];

export const orderColumnData = [
  {
    name: "S.No",
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Order ID & Purchase ID",
    selector: "orderId",
    width: 300 + "px",
  },

  {
    name: "Delivery ",
    selector: "delivery",
    width: 80 + "px",
  },

  {
    name: "Product Name",
    selector: "name",
    width: 350 + "px",
  },
  {
    name: "Variant Name",
    selector: "variant_name",
    width: 100 + "px",
  },

  {
    name: "Status",
    selector: "status",
    width: 80 + "px",
  },

  {
    name: "Price",
    selector: "price",
    width: 80 + "px",
  },
  {
    name: "Quantity",
    selector: "quantity",
    width: 80 + "px",
  },
  {
    name: "Order Placed at ",
    selector: "orderdate",
    width: 190 + "px",
  },
  {
    name: "Actions",
    selector: "actions",
    width: 100 + "px",
  },
];

export const productVariantColumnData = [
  {
    name: "S.No",
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Variant Name",
    selector: "variant_name",
    width: 330 + "px",
  },
  {
    name: "Net Quantity",
    selector: "net_quantity",
    minWidth: 200 + "px",
  },

  {
    name: "Price",
    selector: "price",
    width: 150 + "px",
  },
  {
    name: "Stock",
    selector: "stock",
    width: 150 + "px",
  },
  {
    name: "Actions",
    selector: "actions",
    width: 180 + "px",
  },
];

export const SlotData = [
  {
    name: "S.No",
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Doctors",
    selector: "doctor",
    width: 230 + "px",
  },

  {
    name: "Day of week",
    selector: "day",
    width: 130 + "px",
  },
  {
    name: "Start Time",
    selector: "start_time",
    width: 150 + "px",
  },

  {
    name: "End Time",
    selector: "end_time",
    minWidth: 150 + "px",
  },
  {
    name: "Slot Time",
    selector: "slot_time",
    width: 150 + "px",
  },
  {
    name: "Slot Price",
    selector: "slot_price",
    width: 150 + "px",
  },
  {
    name: "Actions",
    selector: "actions",
    width: 150 + "px",
  },
];

export const AppointmentData = [
  {
    name: "S.No",
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Parent Name",
    selector: "parent_name",
    width: 150 + "px",
  },
  {
    name: "Pet Name",
    selector: "pet_name",
    width: 150 + "px",
  },
  {
    name: "Date",
    selector: "date",
    width: 200 + "px",
  },

  {
    name: "Slot Time",
    selector: "slot_time",
    minWidth: 200 + "px",
  },

  {
    name: "Doctor Name",
    selector: "doctor_name",
    minWidth: 200 + "px",
  },
  {
    name: "Status",
    selector: "status",
    width: 200 + "px",
  },

  // {
  //   name: "Medical Description",
  //   selector: "medical_description",
  //   width: 200 + "px",
  // },
  {
    name: "Actions",
    selector: "actions",
    width: 150 + "px",
  },
];

export const DoctorData = [
  {
    name: "S.No",
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Name",
    selector: "name",
    width: 330 + "px",
  },
  {
    name: "Qualifications",
    selector: "qualifications",
    width: 150 + "px",
  },

  {
    name: "Description",
    selector: "description",
    minWidth: 300 + "px",
  },

  {
    name: "Actions",
    selector: "actions",
    width: 200 + "px",
  },
];

export const ServiceData = [
  {
    name: "S.No",
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Service",
    selector: "service",
    width: 200 + "px",
  },

  {
    name: "Description",
    selector: "description",
    width: 500 + "px",
  },

  {
    name: "Actions",
    selector: "actions",
    width: 200 + "px",
  },
];
