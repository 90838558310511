export const GET_CHECKOUT_PREPAID_ORDER = "GET_CHECKOUT_PREPAID_ORDER";
export const GET_CHECKOUT_PREPAID_ORDER_BEGIN =
  "GET_CHECKOUT_PREPAID_ORDER_BEGIN";
export const GET_CHECKOUT_PREPAID_ORDER_SUCCESS =
  "GET_CHECKOUT_PREPAID_ORDER_SUCCESS";
export const GET_CHECKOUT_PREPAID_ORDER_FAIL =
  "GET_CHECKOUT_PREPAID_ORDER_FAIL";

export const ADD_CHECKOUT_PREPAID_ORDER = "ADD_CHECKOUT_PREPAID_ORDER";
export const ADD_CHECKOUT_PREPAID_ORDER_BEGIN =
  "ADD_CHECKOUT_PREPAID_ORDER_BEGIN";
export const ADD_CHECKOUT_PREPAID_ORDER_SUCCESS =
  "ADD_CHECKOUT_PREPAID_ORDER_SUCCESS";
export const ADD_CHECKOUT_PREPAID_ORDER_FAIL =
  "ADD_CHECKOUT_PREPAID_ORDER_FAIL";

export const ADD_CHECKOUT_COD_ORDER = "ADD_CHECKOUT_COD_ORDER";
export const ADD_CHECKOUT_COD_ORDER_BEGIN = "ADD_CHECKOUT_COD_ORDER_BEGIN";
export const ADD_CHECKOUT_COD_ORDER_SUCCESS = "ADD_CHECKOUT_COD_ORDER_SUCCESS";
export const ADD_CHECKOUT_COD_ORDER_FAIL = "ADD_CHECKOUT_COD_ORDER_FAIL";

export const GET_REWARD_POINTS = "GET_REWARD_POINTS";
export const GET_REWARD_POINTS_BEGIN = "GET_REWARD_POINTS_BEGIN";
export const GET_REWARD_POINTS_SUCCESS = "GET_REWARD_POINTS_SUCCESS";
export const GET_REWARD_POINTS_FAIL = "GET_REWARD_POINTS_FAIL";
