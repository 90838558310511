export const INITIATE_REGISTER_CARER = "INITIATE_REGISTER_CARER";
export const INITIATE_REGISTER_CARER_BEGIN = "INITIATE_REGISTER_CARER_BEGIN";
export const INITIATE_REGISTER_CARER_SUCCESS =
  "INITIATE_REGISTER_CARER_SUCCESS";
export const INITIATE_REGISTER_CARER_FAIL = "INITIATE_REGISTER_CARER_FAIL";

export const REGISTER_CARER = "REGISTER_CARER";
export const REGISTER_CARER_BEGIN = "REGISTER_CARER_BEGIN";
export const REGISTER_CARER_SUCCESS = "REGISTER_CARER_SUCCESS";
export const REGISTER_CARER_FAIL = "REGISTER_CARER_FAIL";

export const VERIFY_CARER_OTP = "VERIFY_CARER_OTP";
export const VERIFY_CARER_OTP_BEGIN = "VERIFY_CARER_OTP_BEGIN";
export const VERIFY_CARER_OTP_SUCCESS = "VERIFY_CARER_OTP_SUCCESS";
export const VERIFY_CARER_OTP_FAIL = "VERIFY_CARER_OTP_FAIL";

export const SEND_CARER_OTP = "SEND_CARER_OTP";
export const SEND_CARER_OTP_BEGIN = "SEND_CARER_OTP_BEGIN";
export const SEND_CARER_OTP_SUCCESS = "SEND_CARER_OTP_SUCCESS";
export const SEND_CARER_OTP_FAIL = "SEND_CARER_OTP_FAIL";
