export const EDIT_CART_DETAILS = "EDIT_CART_DETAILS";
export const EDIT_CART_DETAILS_BEGIN = "EDIT_CART_DETAILS_BEGIN";
export const EDIT_CART_DETAILS_SUCCESS = "EDIT_CART_DETAILS_SUCCESS";
export const EDIT_CART_DETAILS_FAIL = "EDIT_CART_DETAILS_FAIL";

export const ADD_CART_DETAILS = "ADD_CART_DETAILS";
export const ADD_CART_DETAILS_BEGIN = "ADD_CART_DETAILS_BEGIN";
export const ADD_CART_DETAILS_SUCCESS = "ADD_CART_DETAILS_SUCCESS";
export const ADD_CART_DETAILS_FAIL = "ADD_CART_DETAILS_FAIL";

export const DELETE_CART_DETAILS = "DELETE_CART_DETAILS";
export const DELETE_CART_DETAILS_BEGIN = "DELETE_CART_DETAILS_BEGIN";
export const DELETE_CART_DETAILS_SUCCESS = "DELETE_CART_DETAILS_SUCCESS";
export const DELETE_CART_DETAILS_FAIL = "DELETE_CART_DETAILS_FAIL";

export const GET_CART_DETAILS = "GET_CART_DETAILS";
export const GET_CART_DETAILS_BEGIN = "GET_CART_DETAILS_BEGIN";
export const GET_CART_DETAILS_SUCCESS = "GET_CART_DETAILS_SUCCESS";
export const GET_CART_DETAILS_FAIL = "GET_CART_DETAILS_FAIL";

export const GET_CART_BY_ID = "GET_CART_BY_ID";
export const GET_CART_BY_ID_BEGIN = "GET_CART_BY_ID_BEGIN";
export const GET_CART_BY_ID_SUCCESS = "GET_CART_BY_ID_SUCCESS";
export const GET_CART_BY_ID_FAIL = "GET_CART_BY_ID_FAIL";

export const CHANGE_QUANTITY = "CHANGE_QUANTITY";
export const CHANGE_QUANTITY_BEGIN = "CHANGE_QUANTITY_BEGIN";
export const CHANGE_QUANTITY_SUCCESS = "CHANGE_QUANTITY_SUCCESS";
export const CHANGE_QUANTITY_FAIL = "CHANGE_QUANTITY_FAIL";

export const CHANGE_GUEST_QUANTITY = "CHANGE_GUEST_QUANTITY";
export const CHANGE_GUEST_QUANTITY_BEGIN = "CHANGE_GUEST_QUANTITY_BEGIN";
export const CHANGE_GUEST_QUANTITY_SUCCESS = "CHANGE_GUEST_QUANTITY_SUCCESS";
export const CHANGE_GUEST_QUANTITY_FAIL = "CHANGE_GUEST_QUANTITY_FAIL";

export const GET_GUEST_CART = "GET_GUEST_CART";
export const GET_GUEST_CART_BEGIN = "GET_GUEST_CART_BEGIN";
export const GET_GUEST_CART_SUCCESS = "GET_GUEST_CART_SUCCESS";
export const GET_GUEST_CART_FAIL = "GET_GUEST_CART_FAIL";
