export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_BEGIN = "VERIFY_OTP_BEGIN";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";

export const RESET_USER_REGISTER_ERRORS = "RESET_USER_REGISTER_ERRORS";
