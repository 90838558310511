export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const LOGOUT_USER = "LOGOUT_USER";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_BEGIN = "FORGOT_PASSWORD_BEGIN";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const FORGOT_VERIFY_OTP = "FORGOT_VERIFY_OTP";
export const FORGOT_VERIFY_OTP_BEGIN = "FORGOT_VERIFY_OTP_BEGIN";
export const FORGOT_VERIFY_OTP_SUCCESS = "FORGOT_VERIFY_OTP_SUCCESS";
export const FORGOT_VERIFY_OTP_FAIL = "FORGOT_VERIFY_OTP_FAIL";

export const FORGOT_VERIFY_PASSWORD = "FORGOT_VERIFY_PASSWORD";
export const FORGOT_VERIFY_PASSWORD_BEGIN = "FORGOT_VERIFY_PASSWORD_BEGIN";
export const FORGOT_VERIFY_PASSWORD_SUCCESS = "FORGOT_VERIFY_PASSWORD_SUCCESS";
export const FORGOT_VERIFY_PASSWORD_FAIL = "FORGOT_VERIFY_PASSWORD_FAIL";

export const RESET_ERRORS = "RESET_ERRORS";
