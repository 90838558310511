export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_ORDER_DETAILS_BEGIN = "GET_ORDER_DETAILS_BEGIN";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAIL = "GET_ORDER_DETAILS_FAIL";

export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const GET_ORDER_BY_ID_BEGIN = "GET_ORDER_BY_ID_BEGIN";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";
export const GET_ORDER_BY_ID_FAIL = "GET_ORDER_BY_ID_FAIL";

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_BEGIN = "GET_INVOICE_BEGIN";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL";
