export const APPLY_COUPON_DETAILS = "APPLY_COUPON_DETAILS";
export const APPLY_COUPON_DETAILS_BEGIN = "APPLY_COUPON_DETAILS_BEGIN";
export const APPLY_COUPON_DETAILS_FAIL = "APPLY_COUPON_DETAILS_FAIL";
export const APPLY_COUPON_DETAILS_SUCCESS = "APPLY_COUPON_DETAILS_SUCCESS";

export const REMOVE_COUPON_DETAILS = "REMOVE_COUPON_DETAILS";
export const REMOVE_COUPON_DETAILS_BEGIN = "REMOVE_COUPON_DETAILS_BEGIN";
export const REMOVE_COUPON_DETAILS_SUCCESS = "REMOVE_COUPON_DETAILS_SUCCESS";
export const REMOVE_COUPON_DETAILS_FAIL = "REMOVE_COUPON_DETAILS_FAIL";

export const APPLY_CLINIC_COUPON_DETAILS = "APPLY_CLINIC_COUPON_DETAILS";
export const APPLY_CLINIC_COUPON_DETAILS_BEGIN =
  "APPLY_CLINIC_COUPON_DETAILS_BEGIN";
export const APPLY_CLINIC_COUPON_DETAILS_FAIL =
  "APPLY_CLINIC_COUPON_DETAILS_FAIL";
export const APPLY_CLINIC_COUPON_DETAILS_SUCCESS =
  "APPLY_CLINIC_COUPON_DETAILS_SUCCESS";

export const REMOVE_CLINIC_COUPON_DETAILS = "REMOVE_CLINIC_COUPON_DETAILS";
export const REMOVE_CLINIC_COUPON_DETAILS_BEGIN =
  "REMOVE_CLINIC_COUPON_DETAILS_BEGIN";
export const REMOVE_CLINIC_COUPON_DETAILS_SUCCESS =
  "REMOVE_CLINIC_COUPON_DETAILS_SUCCESS";
export const REMOVE_CLINIC_COUPON_DETAILS_FAIL =
  "REMOVE_CLINIC_COUPON_DETAILS_FAIL";
