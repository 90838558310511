export const EDIT_REVIEWS = "EDIT_REVIEWS";
export const EDIT_REVIEWS_BEGIN = "EDIT_REVIEWS_BEGIN";
export const EDIT_REVIEWS_SUCCESS = "EDIT_REVIEWS_SUCCESS";
export const EDIT_REVIEWS_FAIL = "EDIT_REVIEWS_FAIL";

export const ADD_REVIEWS = "ADD_REVIEWS";
export const ADD_REVIEWS_BEGIN = "ADD_REVIEWS_BEGIN";
export const ADD_REVIEWS_SUCCESS = "ADD_REVIEWS_SUCCESS";
export const ADD_REVIEWS_FAIL = "ADD_REVIEWS_FAIL";

export const DELETE_REVIEWS = "DELETE_REVIEWS";
export const DELETE_REVIEWS_BEGIN = "DELETE_REVIEWS_BEGIN";
export const DELETE_REVIEWS_SUCCESS = "DELETE_REVIEWS_SUCCESS";
export const DELETE_REVIEWS_FAIL = "DELETE_REVIEWS_FAIL";

export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_BEGIN = "GET_REVIEWS_BEGIN";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAIL = "GET_REVIEWS_FAIL";

export const GET_REVIEWS_BY_ID = "GET_REVIEWS_BY_ID";
export const GET_REVIEWS_BY_ID_BEGIN = "GET_REVIEWS_BY_ID_BEGIN";
export const GET_REVIEWS_BY_ID_SUCCESS = "GET_REVIEWS_BY_ID_SUCCESS";
export const GET_REVIEWS_BY_ID_FAIL = "GET_REVIEWS_BY_ID_FAIL";

export const GET_LOGGED_USER_REVIEWS = "GET_LOGGED_USER_REVIEWS";
export const GET_LOGGED_USER_REVIEWS_BEGIN = "GET_LOGGED_USER_REVIEWS_BEGIN";
export const GET_LOGGED_USER_REVIEWS_SUCCESS =
  "GET_LOGGED_USER_REVIEWS_SUCCESS";
export const GET_LOGGED_USER_REVIEWS_FAIL = "GET_LOGGED_USER_REVIEWS_FAIL";
