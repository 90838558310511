export const GET_PERSONAL_DETAILS = "GET_PERSONAL_DETAILS";
export const GET_PERSONAL_DETAILS_BEGIN = "GET_PERSONAL_DETAILS_BEGIN";
export const GET_PERSONAL_DETAILS_SUCCESS = "GET_PERSONAL_DETAILS_SUCCESS";
export const GET_PERSONAL_DETAILS_FAIL = "GET_PERSONAL_DETAILS_FAIL";

export const EDIT_PERSONAL_DETAILS = "EDIT_PERSONAL_DETAILS";
export const EDIT_PERSONAL_DETAILS_BEGIN = "EDIT_PERSONAL_DETAILS_BEGIN";
export const EDIT_PERSONAL_DETAILS_SUCCESS = "EDIT_PERSONAL_DETAILS_SUCCESS";
export const EDIT_PERSONAL_DETAILS_FAIL = "EDIT_PERSONAL_DETAILS_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_BEGIN = "CHANGE_PASSWORD_BEGIN";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const RESET_PERSONAL_ERROR = "RESET_PERSONAL_ERROR";
