// ORDER

export const GET_COMPLETED_ORDER_DETAILS = "GET_COMPLETED_ORDER_DETAILS";
export const GET_COMPLETED_ORDER_DETAILS_BEGIN =
  "GET_COMPLETED_ORDER_DETAILS_BEGIN";
export const GET_COMPLETED_ORDER_DETAILS_SUCCESS =
  "GET_COMPLETED_ORDER_DETAILS_SUCCESS";
export const GET_COMPLETED_ORDER_DETAILS_FAIL =
  "GET_COMPLETED_ORDER_DETAILS_FAIL";

export const GET_PENDING_ORDER_DETAILS = "GET_PENDING_ORDER_DETAILS";
export const GET_PENDING_ORDER_DETAILS_BEGIN =
  "GET_PENDING_ORDER_DETAILS_BEGIN";
export const GET_PENDING_ORDER_DETAILS_SUCCESS =
  "GET_PENDING_ORDER_DETAILS_SUCCESS";
export const GET_PENDING_ORDER_DETAILS_FAIL = "GET_PENDING_ORDER_DETAILS_FAIL";

export const GET_COMPLETED_ORDER_BY_ID = "GET_COMPLETED_ORDER_BY_ID";
export const GET_COMPLETED_ORDER_BY_ID_BEGIN =
  "GET_COMPLETED_ORDER_BY_ID_BEGIN";
export const GET_COMPLETED_ORDER_BY_ID_SUCCESS =
  "GET_COMPLETED_ORDER_BY_ID_SUCCESS";
export const GET_COMPLETED_ORDER_BY_ID_FAIL = "GET_COMPLETED_ORDER_BY_ID_FAIL";

export const GET_PENDING_ORDER_BY_ID = "GET_PENDING_ORDER_BY_ID";
export const GET_PENDING_ORDER_BY_ID_BEGIN = "GET_PENDING_ORDER_BY_ID_BEGIN";
export const GET_PENDING_ORDER_BY_ID_SUCCESS =
  "GET_PENDING_ORDER_BY_ID_SUCCESS";
export const GET_PENDING_ORDER_BY_ID_FAIL = "GET_PENDING_ORDER_BY_ID_FAIL";

export const GET_SHIPPED_ORDER_DETAILS = "GET_SHIPPED_ORDER_DETAILS";
export const GET_SHIPPED_ORDER_DETAILS_BEGIN =
  "GET_SHIPPED_ORDER_DETAILS_BEGIN";
export const GET_SHIPPED_ORDER_DETAILS_SUCCESS =
  "GET_SHIPPED_ORDER_DETAILS_SUCCESS";
export const GET_SHIPPED_ORDER_DETAILS_FAIL = "GET_SHIPPED_ORDER_DETAILS_FAIL";

export const GET_SHIPPED_ORDER_BY_ID = "GET_SHIPPED_ORDER_BY_ID";
export const GET_SHIPPED_ORDER_BY_ID_BEGIN = "GET_SHIPPED_ORDER_BY_ID_BEGIN";
export const GET_SHIPPED_ORDER_BY_ID_SUCCESS =
  "GET_SHIPPED_ORDER_BY_ID_SUCCESS";
export const GET_SHIPPED_ORDER_BY_ID_FAIL = "GET_SHIPPED_ORDER_BY_ID_FAIL";

// CATEGORY

export const GET_ORDER_CATEGORY_DETAILS = "GET_ORDER_CATEGORY_DETAILS";
export const GET_ORDER_CATEGORY_DETAILS_BEGIN =
  "GET_ORDER_CATEGORY_DETAILS_BEGIN";
export const GET_ORDER_CATEGORY_DETAILS_SUCCESS =
  "GET_ORDER_CATEGORY_DETAILS_SUCCESS";
export const GET_ORDER_CATEGORY_DETAILS_FAIL =
  "GET_ORDER_CATEGORY_DETAILS_FAIL";

// STATUS
export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const CHANGE_ORDER_STATUS_BEGIN = "CHANGE_ORDER_STATUS_BEGIN";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_STATUS_FAIL = "CHANGE_ORDER_STATUS_FAIL";

// SALES REPORT
export const SALES_REPORT = "SALES_REPORT";
export const SALES_REPORT_BEGIN = "SALES_REPORT_BEGIN";
export const SALES_REPORT_SUCCESS = "SALES_REPORT_SUCCESS";
export const SALES_REPORT_FAIL = "SALES_REPORT_FAIL";

// INVOICE
export const GET_SELLER_INVOICE = "GET_ISELLER_NVOICE";
export const GET_SELLER_INVOICE_BEGIN = "GET_SELLER_INVOICE_BEGIN";
export const GET_SELLER_INVOICE_SUCCESS = "GET_SELLER_INVOICE_SUCCESS";
export const GET_SELLER_INVOICE_FAIL = "GET_SELLER_INVOICE_FAIL";

export const EXPORT_SELLER_INVOICE = "EXPORT_SELLER_INVOICE";
export const EXPORT_SELLER_INVOICE_BEGIN = "EXPORT_SELLER_INVOICE_BEGIN";
export const EXPORT_SELLER_INVOICE_SUCCESS = "EXPORT_SELLER_INVOICE_SUCCESS";
export const EXPORT_SELLER_INVOICE_FAIL = "EXPORT_SELLER_INVOICE_FAIL";
