// SLOT

export const EDIT_SLOT_DETAILS = "EDIT_SLOT_DETAILS";
export const EDIT_SLOT_DETAILS_BEGIN = "EDIT_SLOT_DETAILS_BEGIN";
export const EDIT_SLOT_DETAILS_SUCCESS = "EDIT_SLOT_DETAILS_SUCCESS";
export const EDIT_SLOT_DETAILS_FAIL = "EDIT_SLOT_DETAILS_FAIL";

export const ADD_SLOT_DETAILS = "ADD_SLOT_DETAILS";
export const ADD_SLOT_DETAILS_BEGIN = "ADD_SLOT_DETAILS_BEGIN";
export const ADD_SLOT_DETAILS_SUCCESS = "ADD_SLOT_DETAILS_SUCCESS";
export const ADD_SLOT_DETAILS_FAIL = "ADD_SLOT_DETAILS_FAIL";

export const DELETE_SLOT_DETAILS = "DELETE_SLOT_DETAILS";
export const DELETE_SLOT_DETAILS_BEGIN = "DELETE_SLOT_DETAILS_BEGIN";
export const DELETE_SLOT_DETAILS_SUCCESS = "DELETE_SLOT_DETAILS_SUCCESS";
export const DELETE_SLOT_DETAILS_FAIL = "DELETE_SLOT_DETAILS_FAIL";

export const GET_SLOT_DETAILS = "GET_SLOT_DETAILS";
export const GET_SLOT_DETAILS_BEGIN = "GET_SLOT_DETAILS_BEGIN";
export const GET_SLOT_DETAILS_SUCCESS = "GET_SLOT_DETAILS_SUCCESS";
export const GET_SLOT_DETAILS_FAIL = "GET_SLOT_DETAILS_FAIL";

export const GET_SLOT_BY_ID = "GET_SLOT_BY_ID";
export const GET_SLOT_BY_ID_BEGIN = "GET_SLOT_BY_ID_BEGIN";
export const GET_SLOT_BY_ID_SUCCESS = "GET_SLOT_BY_ID_SUCCESS";
export const GET_SLOT_BY_ID_FAIL = "GET_SLOT_BY_ID_FAIL";

// SLOT PRICE

export const ADD_SLOT_PRICE_DETAILS = "ADD_SLOT_PRICE_DETAILS";
export const ADD_SLOT_PRICE_DETAILS_BEGIN = "ADD_SLOT_PRICE_DETAILS_BEGIN";
export const ADD_SLOT_PRICE_DETAILS_SUCCESS = "ADD_SLOT_PRICE_DETAILS_SUCCESS";
export const ADD_SLOT_PRICE_DETAILS_FAIL = "ADD_SLOT_PRICE_DETAILS_FAIL";

export const GET_SLOT_PRICE_DETAILS = "GET_SLOT_PRICE_DETAILS";
export const GET_SLOT_PRICE_DETAILS_BEGIN = "GET_SLOT_PRICE_DETAILS_BEGIN";
export const GET_SLOT_PRICE_DETAILS_SUCCESS = "GET_SLOT_PRICE_DETAILS_SUCCESS";
export const GET_SLOT_PRICE_DETAILS_FAIL = "GET_SLOT_PRICE_DETAILS_FAIL";

// SLOT STATUS

export const EDIT_SLOT_STATUS_DETAILS = "EDIT_SLOT_STATUS_DETAILS";
export const EDIT_SLOT_STATUS_DETAILS_BEGIN = "EDIT_SLOT_STATUS_DETAILS_BEGIN";
export const EDIT_SLOT_STATUS_DETAILS_SUCCESS =
  "EDIT_SLOT_STATUS_DETAILS_SUCCESS";
export const EDIT_SLOT_STATUS_DETAILS_FAIL = "EDIT_SLOT_STATUS_DETAILS_FAIL";

// CATEGORY

export const GET_SLOT_CATEGORY_DETAILS = "GET_SLOT_CATEGORY_DETAILS";
export const GET_SLOT_CATEGORY_DETAILS_BEGIN =
  "GET_SLOT_CATEGORY_DETAILS_BEGIN";
export const GET_SLOT_CATEGORY_DETAILS_SUCCESS =
  "GET_SLOT_CATEGORY_DETAILS_SUCCESS";
export const GET_SLOT_CATEGORY_DETAILS_FAIL = "GET_SLOT_CATEGORY_DETAILS_FAIL";

// Dashboard

export const GET_CLINIC_DASHBOARD_DETAILS = "GET_CLINIC_DASHBOARD_DETAILS";
export const GET_CLINIC_DASHBOARD_DETAILS_BEGIN =
  "GET_CLINIC_DASHBOARD_DETAILS_BEGIN";
export const GET_CLINIC_DASHBOARD_DETAILS_SUCCESS =
  "GET_CLINIC_DASHBOARD_DETAILS_SUCCESS";
export const GET_CLINIC_DASHBOARD_DETAILS_FAIL =
  "GET_CLINIC_DASHBOARD_DETAILS_FAIL";

export const GET_CLINIC_APPOINTMENT_DETAILS = "GET_CLINIC_APPOINTMENT_DETAILS";
export const GET_CLINIC_APPOINTMENT_DETAILS_BEGIN =
  "GET_CLINIC_APPOINTMENT_DETAILS_BEGIN";
export const GET_CLINIC_APPOINTMENT_DETAILS_SUCCESS =
  "GET_CLINIC_APPOINTMENT_DETAILS_SUCCESS";
export const GET_CLINIC_APPOINTMENT_DETAILS_FAIL =
  "GET_CLINIC_APPOINTMENT_DETAILS_FAIL";

export const CHANGE_APPOINTMENT_STATUS = "CHANGE_APPOINTMENT_STATUS";
export const CHANGE_APPOINTMENT_STATUS_BEGIN =
  "CHANGE_APPOINTMENT_STATUS_BEGIN";
export const CHANGE_APPOINTMENT_STATUS_SUCCESS =
  "CHANGE_APPOINTMENT_STATUS_SUCCESS";
export const CHANGE_APPOINTMENT_STATUS_FAIL = "CHANGE_APPOINTMENT_STATUS_FAIL";

export const CLINIC_APPOINTMENT_RESCHEDULE = "CLINIC_APPOINTMENT_RESCHEDULE";
export const CLINIC_APPOINTMENT_RESCHEDULE_BEGIN =
  "CLINIC_APPOINTMENT_RESCHEDULE_BEGIN";
export const CLINIC_APPOINTMENT_RESCHEDULE_SUCCESS =
  "CLINIC_APPOINTMENT_RESCHEDULE_SUCCESS";
export const CLINIC_APPOINTMENT_RESCHEDULE_FAIL =
  "CLINIC_APPOINTMENT_RESCHEDULE_FAIL";

export const CLINIC_BLOCK_SLOTS = "CLINIC_BLOCK_SLOTS";
export const CLINIC_BLOCK_SLOTS_BEGIN = "CLINIC_BLOCK_SLOTS_BEGIN";
export const CLINIC_BLOCK_SLOTS_SUCCESS = "CLINIC_BLOCK_SLOTS_SUCCESS";
export const CLINIC_BLOCK_SLOTS_FAIL = "CLINIC_BLOCK_SLOTS_FAIL";

export const REMOVE_CLINIC_SLOT_BLOCK = "REMOVE_CLINIC_SLOT_BLOCK";
export const REMOVE_CLINIC_SLOT_BLOCK_BEGIN = "REMOVE_CLINIC_SLOT_BLOCK_BEGIN";
export const REMOVE_CLINIC_SLOT_BLOCK_SUCCESS =
  "REMOVE_CLINIC_SLOT_BLOCK_SUCCESS";
export const REMOVE_CLINIC_SLOT_BLOCK_FAIL = "REMOVE_CLINIC_SLOT_BLOCK_FAIL";

// DOCTOR

export const EDIT_DOCTOR_DETAILS = "EDIT_DOCTOR_DETAILS";
export const EDIT_DOCTOR_DETAILS_BEGIN = "EDIT_DOCTOR_DETAILS_BEGIN";
export const EDIT_DOCTOR_DETAILS_SUCCESS = "EDIT_DOCTOR_DETAILS_SUCCESS";
export const EDIT_DOCTOR_DETAILS_FAIL = "EDIT_DOCTOR_DETAILS_FAIL";

export const ADD_DOCTOR_DETAILS = "ADD_DOCTOR_DETAILS";
export const ADD_DOCTOR_DETAILS_BEGIN = "ADD_DOCTOR_DETAILS_BEGIN";
export const ADD_DOCTOR_DETAILS_SUCCESS = "ADD_DOCTOR_DETAILS_SUCCESS";
export const ADD_DOCTOR_DETAILS_FAIL = "ADD_DOCTOR_DETAILS_FAIL";

export const DELETE_DOCTOR_DETAILS = "DELETE_DOCTOR_DETAILS";
export const DELETE_DOCTOR_DETAILS_BEGIN = "DELETE_DOCTOR_DETAILS_BEGIN";
export const DELETE_DOCTOR_DETAILS_SUCCESS = "DELETE_DOCTOR_DETAILS_SUCCESS";
export const DELETE_DOCTOR_DETAILS_FAIL = "DELETE_DOCTOR_DETAILS_FAIL";

export const GET_DOCTOR_DETAILS = "GET_DOCTOR_DETAILS";
export const GET_DOCTOR_DETAILS_BEGIN = "GET_DOCTOR_DETAILS_BEGIN";
export const GET_DOCTOR_DETAILS_SUCCESS = "GET_DOCTOR_DETAILS_SUCCESS";
export const GET_DOCTOR_DETAILS_FAIL = "GET_DOCTOR_DETAILS_FAIL";

export const GET_DOCTOR_BY_ID = "GET_DOCTOR_BY_ID";
export const GET_DOCTOR_BY_ID_BEGIN = "GET_DOCTOR_BY_ID_BEGIN";
export const GET_DOCTOR_BY_ID_SUCCESS = "GET_DOCTOR_BY_ID_SUCCESS";
export const GET_DOCTOR_BY_ID_FAIL = "GET_DOCTOR_BY_ID_FAIL";

// SERVICE

// SERVICE

export const EDIT_SERVICE_DETAILS = "EDIT_SERVICE_DETAILS";
export const EDIT_SERVICE_DETAILS_BEGIN = "EDIT_SERVICE_DETAILS_BEGIN";
export const EDIT_SERVICE_DETAILS_SUCCESS = "EDIT_SERVICE_DETAILS_SUCCESS";
export const EDIT_SERVICE_DETAILS_FAIL = "EDIT_SERVICE_DETAILS_FAIL";

export const ADD_SERVICE_DETAILS = "ADD_SERVICE_DETAILS";
export const ADD_SERVICE_DETAILS_BEGIN = "ADD_SERVICE_DETAILS_BEGIN";
export const ADD_SERVICE_DETAILS_SUCCESS = "ADD_SERVICE_DETAILS_SUCCESS";
export const ADD_SERVICE_DETAILS_FAIL = "ADD_SERVICE_DETAILS_FAIL";

export const DELETE_SERVICE_DETAILS = "DELETE_SERVICE_DETAILS";
export const DELETE_SERVICE_DETAILS_BEGIN = "DELETE_SERVICE_DETAILS_BEGIN";
export const DELETE_SERVICE_DETAILS_SUCCESS = "DELETE_SERVICE_DETAILS_SUCCESS";
export const DELETE_SERVICE_DETAILS_FAIL = "DELETE_SERVICE_DETAILS_FAIL";

export const GET_SERVICE_DETAILS = "GET_SERVICE_DETAILS";
export const GET_SERVICE_DETAILS_BEGIN = "GET_SERVICE_DETAILS_BEGIN";
export const GET_SERVICE_DETAILS_SUCCESS = "GET_SERVICE_DETAILS_SUCCESS";
export const GET_SERVICE_DETAILS_FAIL = "GET_SERVICE_DETAILS_FAIL";

export const GET_SERVICE_BY_ID = "GET_SERVICE_BY_ID";
export const GET_SERVICE_BY_ID_BEGIN = "GET_SERVICE_BY_ID_BEGIN";
export const GET_SERVICE_BY_ID_SUCCESS = "GET_SERVICE_BY_ID_SUCCESS";
export const GET_SERVICE_BY_ID_FAIL = "GET_SERVICE_BY_ID_FAIL";

export const PUBLISH_DOCTOR = "PUBLISH_DOCTOR";
export const PUBLISH_DOCTOR_BEGIN = "PUBLISH_DOCTOR_BEGIN";
export const PUBLISH_DOCTOR_SUCCESS = "PUBLISH_DOCTOR_SUCCESS";
export const PUBLISH_DOCTOR_FAIL = "PUBLISH_DOCTOR_FAIL";
