export const GET_APPOINTMENT_DETAILS = "GET_APPOINTMENT_DETAILS";
export const GET_APPOINTMENT_DETAILS_BEGIN = "GET_APPOINTMENT_DETAILS_BEGIN";
export const GET_APPOINTMENT_DETAILS_SUCCESS =
  "GET_APPOINTMENT_DETAILS_SUCCESS";
export const GET_APPOINTMENT_DETAILS_FAIL = "GET_APPOINTMENT_DETAILS_FAIL";

export const GET_APPOINTMENT_BY_ID = "GET_APPOINTMENT_BY_ID";
export const GET_APPOINTMENT_BY_ID_BEGIN = "GET_APPOINTMENT_BY_ID_BEGIN";
export const GET_APPOINTMENT_BY_ID_SUCCESS = "GET_APPOINTMENT_BY_ID_SUCCESS";
export const GET_APPOINTMENT_BY_ID_FAIL = "GET_APPOINTMENT_BY_ID_FAIL";

export const ACCEPT_RESCHEDULE_STATUS = "ACCEPT_RESCHEDULE_STATUS";
export const ACCEPT_RESCHEDULE_STATUS_BEGIN = "ACCEPT_RESCHEDULE_STATUS_BEGIN";
export const ACCEPT_RESCHEDULE_STATUS_SUCCESS =
  "ACCEPT_RESCHEDULE_STATUS_SUCCESS";
export const ACCEPT_RESCHEDULE_STATUS_FAIL = "ACCEPT_RESCHEDULE_STATUS_FAIL";

export const REJECT_RESCHEDULE_STATUS = "REJECT_RESCHEDULE_STATUS";
export const REJECT_RESCHEDULE_STATUS_BEGIN = "REJECT_RESCHEDULE_STATUS_BEGIN";
export const REJECT_RESCHEDULE_STATUS_SUCCESS =
  "REJECT_RESCHEDULE_STATUS_SUCCESS";
export const REJECT_RESCHEDULE_STATUS_FAIL = "REJECT_RESCHEDULE_STATUS_FAIL";
