// USER_PRODUCT

export const GET_USER_PRODUCT_DETAILS = "GET_USER_PRODUCT_DETAILS";
export const GET_USER_PRODUCT_DETAILS_BEGIN = "GET_USER_PRODUCT_DETAILS_BEGIN";
export const GET_USER_PRODUCT_DETAILS_SUCCESS =
  "GET_USER_PRODUCT_DETAILS_SUCCESS";
export const GET_USER_PRODUCT_DETAILS_FAIL = "GET_USER_PRODUCT_DETAILS_FAIL";

// USER_PRODUCT BY ID

export const GET_USER_PRODUCT_BY_ID = "GET_USER_PRODUCT_BY_ID";
export const GET_USER_PRODUCT_BY_ID_BEGIN = "GET_USER_PRODUCT_BY_ID_BEGIN";
export const GET_USER_PRODUCT_BY_ID_SUCCESS = "GET_USER_PRODUCT_BY_ID_SUCCESS";
export const GET_USER_PRODUCT_BY_ID_FAIL = "GET_USER_PRODUCT_BY_ID_FAIL";

// USER_PRODUCT VARIANT

export const GET_USER_VARIANT_DETAILS = "GET_USER_VARIANT_DETAILS";
export const GET_USER_VARIANT_DETAILS_BEGIN = "GET_USER_VARIANT_DETAILS_BEGIN";
export const GET_USER_VARIANT_DETAILS_SUCCESS =
  "GET_USER_VARIANT_DETAILS_SUCCESS";
export const GET_USER_VARIANT_DETAILS_FAIL = "GET_USER_VARIANT_DETAILS_FAIL";

export const GET_USER_VARIANT_BY_ID = "GET_USER_VARIANT_BY_ID";
export const GET_USER_VARIANT_BY_ID_BEGIN = "GET_USER_VARIANT_BY_ID_BEGIN";
export const GET_USER_VARIANT_BY_ID_SUCCESS = "GET_USER_VARIANT_BY_ID_SUCCESS";
export const GET_USER_VARIANT_BY_ID_FAIL = "GET_USER_VARIANT_BY_ID_FAIL";

// USER_PRODUCT CATEGORY

export const GET_USER_PRODUCT_CATEGORY_DETAILS =
  "GET_USER_PRODUCT_CATEGORY_DETAILS";
export const GET_USER_PRODUCT_CATEGORY_DETAILS_BEGIN =
  "GET_USER_PRODUCT_CATEGORY_DETAILS_BEGIN";
export const GET_USER_PRODUCT_CATEGORY_DETAILS_SUCCESS =
  "GET_USER_PRODUCT_CATEGORY_DETAILS_SUCCESS";
export const GET_USER_PRODUCT_CATEGORY_DETAILS_FAIL =
  "GET_USER_PRODUCT_CATEGORY_DETAILS_FAIL";

// Brands

export const GET_PRODUCT_BRANDS = "GET_PRODUCT_BRANDS";
export const GET_PRODUCT_BRANDS_BEGIN = "GET_PRODUCT_BRANDS_BEGIN";
export const GET_PRODUCT_BRANDS_SUCCESS = "GET_PRODUCT_BRANDS_SUCCESS";
export const GET_PRODUCT_BRANDS_FAIL = "GET_PRODUCT_BRANDS_FAIL";

// Pin code

export const CHECK_PINCODE = "CHECK_PINCODE";
export const CHECK_PINCODE_BEGIN = "CHECK_PINCODE_BEGIN";
export const CHECK_PINCODE_SUCCESS = "CHECK_PINCODE_SUCCESS";
export const CHECK_PINCODE_FAIL = "CHECK_PINCODE_FAIL";
