// PRODUCT

export const EDIT_PRODUCT_DETAILS = "EDIT_PRODUCT_DETAILS";
export const EDIT_PRODUCT_DETAILS_BEGIN = "EDIT_PRODUCT_DETAILS_BEGIN";
export const EDIT_PRODUCT_DETAILS_SUCCESS = "EDIT_PRODUCT_DETAILS_SUCCESS";
export const EDIT_PRODUCT_DETAILS_FAIL = "EDIT_PRODUCT_DETAILS_FAIL";

export const ADD_PRODUCT_DETAILS = "ADD_PRODUCT_DETAILS";
export const ADD_PRODUCT_DETAILS_BEGIN = "ADD_PRODUCT_DETAILS_BEGIN";
export const ADD_PRODUCT_DETAILS_SUCCESS = "ADD_PRODUCT_DETAILS_SUCCESS";
export const ADD_PRODUCT_DETAILS_FAIL = "ADD_PRODUCT_DETAILS_FAIL";

export const DELETE_PRODUCT_DETAILS = "DELETE_PRODUCT_DETAILS";
export const DELETE_PRODUCT_DETAILS_BEGIN = "DELETE_PRODUCT_DETAILS_BEGIN";
export const DELETE_PRODUCT_DETAILS_SUCCESS = "DELETE_PRODUCT_DETAILS_SUCCESS";
export const DELETE_PRODUCT_DETAILS_FAIL = "DELETE_PRODUCT_DETAILS_FAIL";

export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_BEGIN = "GET_PRODUCT_DETAILS_BEGIN";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL";

export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";
export const GET_PRODUCT_BY_ID_BEGIN = "GET_PRODUCT_BY_ID_BEGIN";
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS";
export const GET_PRODUCT_BY_ID_FAIL = "GET_PRODUCT_BY_ID_FAIL";

export const ADD_PRODUCT_IMAGE = "ADD_PRODUCT_IMAGE";
export const ADD_PRODUCT_IMAGE_BEGIN = "ADD_PRODUCT_IMAGE_BEGIN";
export const ADD_PRODUCT_IMAGE_SUCCESS = "ADD_PRODUCT_IMAGE_SUCCESS";
export const ADD_PRODUCT_IMAGE_FAIL = "ADD_PRODUCT_IMAGE_FAIL";

// CATEGORY

export const GET_PRODUCT_CATEGORY_DETAILS = "GET_PRODUCT_CATEGORY_DETAILS";
export const GET_PRODUCT_CATEGORY_DETAILS_BEGIN =
  "GET_PRODUCT_CATEGORY_DETAILS_BEGIN";
export const GET_PRODUCT_CATEGORY_DETAILS_SUCCESS =
  "GET_PRODUCT_CATEGORY_DETAILS_SUCCESS";
export const GET_PRODUCT_CATEGORY_DETAILS_FAIL =
  "GET_PRODUCT_CATEGORY_DETAILS_FAIL";

// VARIANT

export const EDIT_VARIANT_DETAILS = "EDIT_VARIANT_DETAILS";
export const EDIT_VARIANT_DETAILS_BEGIN = "EDIT_VARIANT_DETAILS_BEGIN";
export const EDIT_VARIANT_DETAILS_SUCCESS = "EDIT_VARIANT_DETAILS_SUCCESS";
export const EDIT_VARIANT_DETAILS_FAIL = "EDIT_VARIANT_DETAILS_FAIL";

export const ADD_VARIANT_DETAILS = "ADD_VARIANT_DETAILS";
export const ADD_VARIANT_DETAILS_BEGIN = "ADD_VARIANT_DETAILS_BEGIN";
export const ADD_VARIANT_DETAILS_SUCCESS = "ADD_VARIANT_DETAILS_SUCCESS";
export const ADD_VARIANT_DETAILS_FAIL = "ADD_VARIANT_DETAILS_FAIL";

export const DELETE_VARIANT_DETAILS = "DELETE_VARIANT_DETAILS";
export const DELETE_VARIANT_DETAILS_BEGIN = "DELETE_VARIANT_DETAILS_BEGIN";
export const DELETE_VARIANT_DETAILS_SUCCESS = "DELETE_VARIANT_DETAILS_SUCCESS";
export const DELETE_VARIANT_DETAILS_FAIL = "DELETE_VARIANT_DETAILS_FAIL";

export const GET_VARIANT_DETAILS = "GET_VARIANT_DETAILS";
export const GET_VARIANT_DETAILS_BEGIN = "GET_VARIANT_DETAILS_BEGIN";
export const GET_VARIANT_DETAILS_SUCCESS = "GET_VARIANT_DETAILS_SUCCESS";
export const GET_VARIANT_DETAILS_FAIL = "GET_VARIANT_DETAILS_FAIL";

export const GET_VARIANT_BY_ID = "GET_VARIANT_BY_ID";
export const GET_VARIANT_BY_ID_BEGIN = "GET_VARIANT_BY_ID_BEGIN";
export const GET_VARIANT_BY_ID_SUCCESS = "GET_VARIANT_BY_ID_SUCCESS";
export const GET_VARIANT_BY_ID_FAIL = "GET_VARIANT_BY_ID_FAIL";

// FEATURE

export const EDIT_FEATURES_DETAILS = "EDIT_FEATURES_DETAILS";
export const EDIT_FEATURES_DETAILS_BEGIN = "EDIT_FEATURES_DETAILS_BEGIN";
export const EDIT_FEATURES_DETAILS_SUCCESS = "EDIT_FEATURES_DETAILS_SUCCESS";
export const EDIT_FEATURES_DETAILS_FAIL = "EDIT_FEATURES_DETAILS_FAIL";

export const ADD_FEATURES_DETAILS = "ADD_FEATURES_DETAILS";
export const ADD_FEATURES_DETAILS_BEGIN = "ADD_FEATURES_DETAILS_BEGIN";
export const ADD_FEATURES_DETAILS_SUCCESS = "ADD_FEATURES_DETAILS_SUCCESS";
export const ADD_FEATURES_DETAILS_FAIL = "ADD_FEATURES_DETAILS_FAIL";

export const GET_FEATURES_DETAILS = "GET_FEATURES_DETAILS";
export const GET_FEATURES_DETAILS_BEGIN = "GET_FEATURES_DETAILS_BEGIN";
export const GET_FEATURES_DETAILS_SUCCESS = "GET_FEATURES_DETAILS_SUCCESS";
export const GET_FEATURES_DETAILS_FAIL = "GET_FEATURES_DETAILS_FAIL";

export const GET_FEATURES_BY_ID = "GET_FEATURES_BY_ID";
export const GET_FEATURES_BY_ID_BEGIN = "GET_FEATURES_BY_ID_BEGIN";
export const GET_FEATURES_BY_ID_SUCCESS = "GET_FEATURES_BY_ID_SUCCESS";
export const GET_FEATURES_BY_ID_FAIL = "GET_FEATURES_BY_ID_FAIL";

// GST

export const GET_GST_DETAILS = "GET_GST_DETAILS";
export const GET_GST_DETAILS_BEGIN = "GET_GST_DETAILS_BEGIN";
export const GET_GST_DETAILS_SUCCESS = "GET_GST_DETAILS_SUCCESS";
export const GET_GST_DETAILS_FAIL = "GET_GST_DETAILS_FAIL";

// VARIANT SUGGESTION

export const GET_PRODUCT_SUGGESTION = "GET_PRODUCT_SUGGESTION";
export const GET_PRODUCT_SUGGESTION_BEGIN = "GET_PRODUCT_SUGGESTION_BEGIN";
export const GET_PRODUCT_SUGGESTION_SUCCESS = "GET_PRODUCT_SUGGESTION_SUCCESS";
export const GET_PRODUCT_SUGGESTION_FAIL = "GET_PRODUCT_SUGGESTION_FAIL";

// Feature suggestion

export const GET_PRODUCT_FEATURE_SUGGESTION = "GET_PRODUCT_FEATURE_SUGGESTION";
export const GET_PRODUCT_FEATURE_SUGGESTION_BEGIN =
  "GET_PRODUCT_FEATURE_SUGGESTION_BEGIN";
export const GET_PRODUCT_FEATURE_SUGGESTION_SUCCESS =
  "GET_PRODUCT_FEATURE_SUGGESTION_SUCCESS";
export const GET_PRODUCT_FEATURE_SUGGESTION_FAIL =
  "GET_PRODUCT_FEATURE_SUGGESTION_FAIL";

// BULK UPLOAD

export const BULK_PRODUCT_UPLOAD = "BULK_PRODUCT_UPLOAD";
export const BULK_PRODUCT_UPLOAD_BEGIN = "BULK_PRODUCT_UPLOAD_BEGIN";
export const BULK_PRODUCT_UPLOAD_SUCCESS = "BULK_PRODUCT_UPLOAD_SUCCESS";
export const BULK_PRODUCT_UPLOAD_FAIL = "BULK_PRODUCT_UPLOAD_FAIL";
