// Carer Type

export const GET_CARER_TYPE = "GET_CARER_TYPE";
export const GET_CARER_TYPE_BEGIN = "GET_CARER_TYPE_BEGIN";
export const GET_CARER_TYPE_SUCCESS = "GET_CARER_TYPE_SUCCESS";
export const GET_CARER_TYPE_FAIL = "GET_CARER_TYPE_FAIL";

// Carer Login

export const CARER_LOGIN_USER = "CARER_LOGIN_USER";
export const CARER_LOGIN_USER_BEGIN = "CARER_LOGIN_USER_BEGIN";
export const CARER_LOGIN_USER_SUCCESS = "CARER_LOGIN_USER_SUCCESS";
export const CARER_LOGIN_USER_FAIL = "CARER_LOGIN_USER_FAIL";

// Carer Personal Data

export const GET_CARER_PERSONAL_DETAILS = "GET_CARER_PERSONAL_DETAILS";
export const GET_CARER_PERSONAL_DETAILS_BEGIN =
  "GET_CARER_PERSONAL_DETAILS_BEGIN";
export const GET_CARER_PERSONAL_DETAILS_SUCCESS =
  "GET_CARER_PERSONAL_DETAILS_SUCCESS";
export const GET_CARER_PERSONAL_DETAILS_FAIL =
  "GET_CARER_PERSONAL_DETAILS_FAIL";

// Complete Carer Personal Data

export const ADD_CARER = "ADD_CARER";
export const ADD_CARER_BEGIN = "ADD_CARER_BEGIN";
export const ADD_CARER_SUCCESS = "ADD_CARER_SUCCESS";
export const ADD_CARER_FAIL = "ADD_CARER_FAIL";

// Carer Dashboard

export const GET_CARER_DASHBOARD_DETAILS = "GET_CARER_DASHBOARD_DETAILS";
export const GET_CARER_DASHBOARD_DETAILS_BEGIN =
  "GET_CARER_DASHBOARD_DETAILS_BEGIN";
export const GET_CARER_DASHBOARD_DETAILS_SUCCESS =
  "GET_CARER_DASHBOARD_DETAILS_SUCCESS";
export const GET_CARER_DASHBOARD_DETAILS_FAIL =
  "GET_CARER_DASHBOARD_DETAILS_FAIL";

// Update Carer Personal Data

export const UPDATE_CARER = "UPDATE_CARER";
export const UPDATE_CARER_BEGIN = "UPDATE_CARER_BEGIN";
export const UPDATE_CARER_SUCCESS = "UPDATE_CARER_SUCCESS";
export const UPDATE_CARER_FAIL = "UPDATE_CARER_FAIL";
