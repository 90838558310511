export const ADD_DONATION_DETAILS = "ADD_DONATION_DETAILS";
export const ADD_DONATION_DETAILS_BEGIN = "ADD_DONATION_DETAILS_BEGIN";
export const ADD_DONATION_DETAILS_SUCCESS = "ADD_DONATION_DETAILS_SUCCESS";
export const ADD_DONATION_DETAILS_FAIL = "ADD_DONATION_DETAILS_FAIL";

export const ADD_DONATION_PAYMENT = "ADD_DONATION_PAYMENT";
export const ADD_DONATION_PAYMENT_BEGIN = "ADD_DONATION_PAYMENT_BEGIN";
export const ADD_DONATION_PAYMENT_SUCCESS = "ADD_DONATION_PAYMENT_SUCCESS";
export const ADD_DONATION_PAYMENT_FAIL = "ADD_DONATION_PAYMENT_FAIL";

export const VIEW_HANDS4PAWS_GALLERY = "VIEW_HANDS4PAWS_GALLERY";
export const VIEW_HANDS4PAWS_GALLERY_BEGIN = "VIEW_HANDS4PAWS_GALLERY_BEGIN";
export const VIEW_HANDS4PAWS_GALLERY_SUCCESS =
  "VIEW_HANDS4PAWS_GALLERY_SUCCESS";
export const VIEW_HANDS4PAWS_GALLERY_FAIL = "VIEW_HANDS4PAWS_GALLERY_FAIL";
