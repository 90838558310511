// CLINIC

export const GET_CLINIC_DETAILS = "GET_CLINIC_DETAILS";
export const GET_CLINIC_DETAILS_BEGIN = "GET_CLINIC_DETAILS_BEGIN";
export const GET_CLINIC_DETAILS_SUCCESS = "GET_CLINIC_DETAILS_SUCCESS";
export const GET_CLINIC_DETAILS_FAIL = "GET_CLINIC_DETAILS_FAIL";

// CLINIC BY ID

export const GET_CLINIC_BY_ID = "GET_CLINIC_BY_ID";
export const GET_CLINIC_BY_ID_BEGIN = "GET_CLINIC_BY_ID_BEGIN";
export const GET_CLINIC_BY_ID_SUCCESS = "GET_CLINIC_BY_ID_SUCCESS";
export const GET_CLINIC_BY_ID_FAIL = "GET_CLINIC_BY_ID_FAIL";

// CLINIC VARIANT

export const GET_USER_VARIANT_DETAILS = "GET_USER_VARIANT_DETAILS";
export const GET_USER_VARIANT_DETAILS_BEGIN = "GET_USER_VARIANT_DETAILS_BEGIN";
export const GET_USER_VARIANT_DETAILS_SUCCESS =
  "GET_USER_VARIANT_DETAILS_SUCCESS";
export const GET_USER_VARIANT_DETAILS_FAIL = "GET_USER_VARIANT_DETAILS_FAIL";

export const GET_USER_VARIANT_BY_ID = "GET_USER_VARIANT_BY_ID";
export const GET_USER_VARIANT_BY_ID_BEGIN = "GET_USER_VARIANT_BY_ID_BEGIN";
export const GET_USER_VARIANT_BY_ID_SUCCESS = "GET_USER_VARIANT_BY_ID_SUCCESS";
export const GET_USER_VARIANT_BY_ID_FAIL = "GET_USER_VARIANT_BY_ID_FAIL";

// CLINIC CATEGORY

export const GET_CLINIC_CATEGORY_DETAILS = "GET_CLINIC_CATEGORY_DETAILS";
export const GET_CLINIC_CATEGORY_DETAILS_BEGIN =
  "GET_CLINIC_CATEGORY_DETAILS_BEGIN";
export const GET_CLINIC_CATEGORY_DETAILS_SUCCESS =
  "GET_CLINIC_CATEGORY_DETAILS_SUCCESS";
export const GET_CLINIC_CATEGORY_DETAILS_FAIL =
  "GET_CLINIC_CATEGORY_DETAILS_FAIL";

// PAYMENT
export const BOOK_CLINIC_APPOINTMENT = "BOOK_CLINIC_APPOINTMENT";
export const BOOK_CLINIC_APPOINTMENT_BEGIN = "BOOK_CLINIC_APPOINTMENT_BEGIN";
export const BOOK_CLINIC_APPOINTMENT_SUCCESS =
  "BOOK_CLINIC_APPOINTMENT_SUCCESS";
export const BOOK_CLINIC_APPOINTMENT_FAIL = "BOOK_CLINIC_APPOINTMENT_FAIL";

export const INITIATE_CLINIC_PAYMENT = "INITIATE_CLINIC_PAYMENT";
export const INITIATE_CLINIC_PAYMENT_BEGIN = "INITIATE_CLINIC_PAYMENT_BEGIN";
export const INITIATE_CLINIC_PAYMENT_SUCCESS =
  "INITIATE_CLINIC_PAYMENT_SUCCESS";
export const INITIATE_CLINIC_PAYMENT_FAIL = "INITIATE_CLINIC_PAYMENT_FAIL";

export const PROCESS_CLINIC_PAYMENT = "PROCESS_CLINIC_PAYMENT";
export const PROCESS_CLINIC_PAYMENT_BEGIN = "PROCESS_CLINIC_PAYMENT_BEGIN";
export const PROCESS_CLINIC_PAYMENT_SUCCESS = "PROCESS_CLINIC_PAYMENT_SUCCESS";
export const PROCESS_CLINIC_PAYMENT_FAIL = "PROCESS_CLINIC_PAYMENT_FAIL";

export const ADD_CLINIC_PAYMENT = "ADD_CLINIC_PAYMENT";
export const ADD_CLINIC_PAYMENT_BEGIN = "ADD_CLINIC_PAYMENT_BEGIN";
export const ADD_CLINIC_PAYMENT_SUCCESS = "ADD_CLINIC_PAYMENT_SUCCESS";
export const ADD_CLINIC_PAYMENT_FAIL = "ADD_CLINIC_PAYMENT_FAIL";

export const GET_CLINIC_PAYMENT = "GET_CLINIC_PAYMENT";
export const GET_CLINIC_PAYMENT_BEGIN = "GET_CLINIC_PAYMENT_BEGIN";
export const GET_CLINIC_PAYMENT_SUCCESS = "GET_CLINIC_PAYMENT_SUCCESS";
export const GET_CLINIC_PAYMENT_FAIL = "GET_CLINIC_PAYMENT_FAIL";

// REVIEWS

export const EDIT_CLINIC_REVIEWS = "EDIT_CLINIC_REVIEWS";
export const EDIT_CLINIC_REVIEWS_BEGIN = "EDIT_CLINIC_REVIEWS_BEGIN";
export const EDIT_CLINIC_REVIEWS_SUCCESS = "EDIT_CLINIC_REVIEWS_SUCCESS";
export const EDIT_CLINIC_REVIEWS_FAIL = "EDIT_CLINIC_REVIEWS_FAIL";

export const ADD_CLINIC_REVIEWS = "ADD_CLINIC_REVIEWS";
export const ADD_CLINIC_REVIEWS_BEGIN = "ADD_CLINIC_REVIEWS_BEGIN";
export const ADD_CLINIC_REVIEWS_SUCCESS = "ADD_CLINIC_REVIEWS_SUCCESS";
export const ADD_CLINIC_REVIEWS_FAIL = "ADD_CLINIC_REVIEWS_FAIL";

export const DELETE_CLINIC_REVIEWS = "DELETE_CLINIC_REVIEWS";
export const DELETE_CLINIC_REVIEWS_BEGIN = "DELETE_CLINIC_REVIEWS_BEGIN";
export const DELETE_CLINIC_REVIEWS_SUCCESS = "DELETE_CLINIC_REVIEWS_SUCCESS";
export const DELETE_CLINIC_REVIEWS_FAIL = "DELETE_CLINIC_REVIEWS_FAIL";

export const GET_CLINIC_REVIEWS = "GET_CLINIC_REVIEWS";
export const GET_CLINIC_REVIEWS_BEGIN = "GET_CLINIC_REVIEWS_BEGIN";
export const GET_CLINIC_REVIEWS_SUCCESS = "GET_CLINIC_REVIEWS_SUCCESS";
export const GET_CLINIC_REVIEWS_FAIL = "GET_CLINIC_REVIEWS_FAIL";
export const GET_CLINIC_REVIEW_BY_ID = "GET_CLINIC_REVIEW_BY_ID";
export const GET_CLINIC_REVIEW_BY_ID_BEGIN = "GET_CLINIC_REVIEW_BY_ID_BEGIN";
export const GET_CLINIC_REVIEW_BY_ID_SUCCESS =
  "GET_CLINIC_REVIEW_BY_ID_SUCCESS";
export const GET_CLINIC_REVIEW_BY_ID_FAIL = "GET_CLINIC_REVIEW_BY_ID_FAIL";

export const GET_LOGGED_CLINIC_REVIEWS = "GET_LOGGED_CLINIC_REVIEWS";
export const GET_LOGGED_CLINIC_REVIEWS_BEGIN =
  "GET_LOGGED_CLINIC_REVIEWS_BEGIN";
export const GET_LOGGED_CLINIC_REVIEWS_SUCCESS =
  "GET_LOGGED_CLINIC_REVIEWS_SUCCESS";
export const GET_LOGGED_CLINIC_REVIEWS_FAIL = "GET_LOGGED_CLINIC_REVIEWS_FAIL";

// Services

export const GET_CLINIC_SERVICES = "GET_CLINIC_SERVICES";
export const GET_CLINIC_SERVICES_BEGIN = "GET_CLINIC_SERVICES_BEGIN";
export const GET_CLINIC_SERVICES_SUCCESS = "GET_CLINIC_SERVICES_SUCCESS";
export const GET_CLINIC_SERVICES_FAIL = "GET_CLINIC_SERVICES_FAIL";

export const REQUEST_CLINIC_BOOKING = "REQUEST_CLINIC_BOOKING";
export const REQUEST_CLINIC_BOOKING_BEGIN = "REQUEST_CLINIC_BOOKING_BEGIN";
export const REQUEST_CLINIC_BOOKING_SUCCESS = "REQUEST_CLINIC_BOOKING_SUCCESS";
export const REQUEST_CLINIC_BOOKING_FAIL = "REQUEST_CLINIC_BOOKING_FAIL";

export const GET_CLINIC_BOOKING_FEE = "GET_CLINIC_BOOKING_FEE";
export const GET_CLINIC_BOOKING_FEE_BEGIN = "GET_CLINIC_BOOKING_FEE_BEGIN";
export const GET_CLINIC_BOOKING_FEE_SUCCESS = "GET_CLINIC_BOOKING_FEE_SUCCESS";
export const GET_CLINIC_BOOKING_FEE_FAIL = "GET_CLINIC_BOOKING_FEE_FAIL";

export const GET_CLINIC_SLOT_BY_ID = "GET_CLINIC_SLOT__BY_ID";
export const GET_CLINIC_SLOT_BY_ID_BEGIN = "GET_CLINIC_SLOT_BY_ID_BEGIN";
export const GET_CLINIC_SLOT_BY_ID_SUCCESS = "GET_CLINIC_SLOT_BY_ID_SUCCESS";
export const GET_CLINIC_SLOT_BY_ID_FAIL = "GET_CLINIC_SLOT_BY_ID_FAIL";
