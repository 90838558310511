import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPAGEById } from "../../store/UserStore/PageContent/action";
const RefundAndCancellation = () => {
  const PageContent = useSelector((state) => state.PageContent);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getPAGEById({ data: { id: "93dfd369-6ed2-45ae-aeea-a246f059c2de" } })
    );
  }, []);
  return (
    <div className="common-container custom-refund-container  mb-100">
      {/* <h2 className="ml-5">Refund and Cancellation Policy</h2>
      <div className="common-container mt-5">
        <p>
          In the event, if you are displeased with the services provided, we
          will refund back the money, provided the reasons are genuine and
          proved after investigation. Please read the fine prints of each deal
          before buying it, it provides all the details about the services or
          the product you purchase.
          <br />
          <br />
          In case of dissatisfaction from our services, clients have the liberty
          to cancel their purchases and request a refund from us. Our Policy for
          the cancellation and refund will be as follows:
        </p>
        <p>
          <strong>Refund and Cancellation Policy</strong>
          <br />
          For Cancellations or Refund, please contact the us at
          info@pawwalker.in.
          <br />
          <br />
          Cancellation of products purchased will be refunded until it is
          dispatched. If the product is dispatched, 50% of the selling price
          will be refunded.
        </p>
        <p>
          Cancellation of appointments booked will be refunded for completely if
          cancelled within 30 minutes after the booking is confirmed by the
          clinic. Post 30 minutes from the confirmation of the appointment by
          the clinic, 60% will be refunded to the user.
        </p>
        <p>
          <br />
          The amount will be refunded to the same details from which the payment
          was initially completed.
        </p>
      </div> */}
      <div
        className="responsive-policy-content"
        dangerouslySetInnerHTML={{
          __html: PageContent?.PageContentDetails?.page_content,
        }}
      />
    </div>
  );
};

export default RefundAndCancellation;
