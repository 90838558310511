export const EDIT_ADDRESS_DETAILS = "EDIT_ADDRESS_DETAILS";
export const EDIT_ADDRESS_DETAILS_BEGIN = "EDIT_ADDRESS_DETAILS_BEGIN";
export const EDIT_ADDRESS_DETAILS_SUCCESS = "EDIT_ADDRESS_DETAILS_SUCCESS";
export const EDIT_ADDRESS_DETAILS_FAIL = "EDIT_ADDRESS_DETAILS_FAIL";

export const ADD_ADDRESS_DETAILS = "ADD_ADDRESS_DETAILS";
export const ADD_ADDRESS_DETAILS_BEGIN = "ADD_ADDRESS_DETAILS_BEGIN";
export const ADD_ADDRESS_DETAILS_SUCCESS = "ADD_ADDRESS_DETAILS_SUCCESS";
export const ADD_ADDRESS_DETAILS_FAIL = "ADD_ADDRESS_DETAILS_FAIL";

export const DELETE_ADDRESS_DETAILS = "DELETE_ADDRESS_DETAILS";
export const DELETE_ADDRESS_DETAILS_BEGIN = "DELETE_ADDRESS_DETAILS_BEGIN";
export const DELETE_ADDRESS_DETAILS_SUCCESS = "DELETE_ADDRESS_DETAILS_SUCCESS";
export const DELETE_ADDRESS_DETAILS_FAIL = "DELETE_ADDRESS_DETAILS_FAIL";

export const GET_ADDRESS_DETAILS = "GET_ADDRESS_DETAILS";
export const GET_ADDRESS_DETAILS_BEGIN = "GET_ADDRESS_DETAILS_BEGIN";
export const GET_ADDRESS_DETAILS_SUCCESS = "GET_ADDRESS_DETAILS_SUCCESS";
export const GET_ADDRESS_DETAILS_FAIL = "GET_ADDRESS_DETAILS_FAIL";

export const GET_BILLING_ADDRESS_DETAILS = "GET_BILLING_ADDRESS_DETAILS";
export const GET_BILLING_ADDRESS_DETAILS_BEGIN =
  "GET_BILLING_ADDRESS_DETAILS_BEGIN";
export const GET_BILLING_ADDRESS_DETAILS_SUCCESS =
  "GET_BILLING_ADDRESS_DETAILS_SUCCESS";
export const GET_BILLING_ADDRESS_DETAILS_FAIL =
  "GET_BILLING_ADDRESS_DETAILS_FAIL";

export const GET_ADDRESS_BY_ID = "GET_ADDRESS_BY_ID";
export const GET_ADDRESS_BY_ID_BEGIN = "GET_ADDRESS_BY_ID_BEGIN";
export const GET_ADDRESS_BY_ID_SUCCESS = "GET_ADDRESS_BY_ID_SUCCESS";
export const GET_ADDRESS_BY_ID_FAIL = "GET_ADDRESS_BY_ID_FAIL";

export const GET_BILLING_ADDRESS_BY_ID = "GET_BILLING_ADDRESS_BY_ID";
export const GET_BILLING_ADDRESS_BY_ID_BEGIN =
  "GET_BILLING_ADDRESS_BY_ID_BEGIN";
export const GET_BILLING_ADDRESS_BY_ID_SUCCESS =
  "GET_BILLING_ADDRESS_BY_ID_SUCCESS";
export const GET_BILLING_ADDRESS_BY_ID_FAIL = "GET_BILLING_ADDRESS_BY_ID_FAIL";
