export const ADD_GUEST = "ADD_GUEST";
export const ADD_GUEST_BEGIN = "ADD_GUEST_BEGIN";
export const ADD_GUEST_SUCCESS = "ADD_GUEST_SUCCESS";
export const ADD_GUEST_FAIL = "ADD_GUEST_FAIL";

export const ADD_GUEST_DETAILS = "ADD_GUEST_DETAILS";
export const ADD_GUEST_DETAILS_BEGIN = "ADD_GUEST_DETAILS_BEGIN";
export const ADD_GUEST_DETAILS_SUCCESS = "ADD_GUEST_DETAILS_SUCCESS";
export const ADD_GUEST_DETAILS_FAIL = "ADD_GUEST_DETAILS_FAIL";

export const GET_GUEST_DETAILS = "GET_GUEST_DETAILS";
export const GET_GUEST_DETAILS_BEGIN = "GET_GUEST_DETAILS_BEGIN";
export const GET_GUEST_DETAILS_SUCCESS = "GET_GUEST_DETAILS_SUCCESS";
export const GET_GUEST_DETAILS_FAIL = "GET_GUEST_DETAILS_FAIL";

export const REGISTER_GUEST = "REGISTER_GUEST";
export const REGISTER_GUEST_BEGIN = "REGISTER_GUEST_BEGIN";
export const REGISTER_GUEST_SUCCESS = "REGISTER_GUEST_SUCCESS";
export const REGISTER_GUEST_FAIL = "REGISTER_GUEST_FAIL";

export const VERIFY_OTP_GUEST = "VERIFY_OTP_GUEST";
export const VERIFY_OTP_GUEST_BEGIN = "VERIFY_OTP_GUEST_BEGIN";
export const VERIFY_OTP_GUEST_SUCCESS = "VERIFY_OTP_GUEST_SUCCESS";
export const VERIFY_OTP_GUEST_FAIL = "VERIFY_OTP_GUEST_FAIL";

export const RESET_GUEST_REGISTER_ERRORS = "RESET_GUEST_REGISTER_ERRORS";

export const ADD_GUEST_ADDRESS_DETAILS = "ADD_GUEST_ADDRESS_DETAILS";
export const ADD_GUEST_ADDRESS_DETAILS_BEGIN =
  "ADD_GUEST_ADDRESS_DETAILS_BEGIN";
export const ADD_GUEST_ADDRESS_DETAILS_SUCCESS =
  "ADD_GUEST_ADDRESS_DETAILS_SUCCESS";
export const ADD_GUEST_ADDRESS_DETAILS_FAIL =
  "ADD_GUEST_BILLING_ADDRESS_DETAILS_FAIL";

export const ADD_GUEST_BILLING_ADDRESS_DETAILS =
  "ADD_GUEST_BILLING_ADDRESS_DETAILS";
export const ADD_GUEST_BILLING_ADDRESS_DETAILS_BEGIN =
  "ADD_GUEST_BILLING_ADDRESS_DETAILS_BEGIN";
export const ADD_GUEST_BILLING_ADDRESS_DETAILS_SUCCESS =
  "ADD_GUEST_ADDRESS_DETAILS_SUCCESS";
export const ADD_GUEST_BILLING_ADDRESS_DETAILS_FAIL =
  "ADD_GUEST_BILLING_ADDRESS_DETAILS_FAIL";

export const GET_GUEST_BILLING_ADDRESS_DETAILS =
  "GET_GUEST_BILLING_ADDRESS_DETAILS";
export const GET_GUEST_BILLING_ADDRESS_DETAILS_BEGIN =
  "GET_GUEST_BILLING_ADDRESS_DETAILS_BEGIN";
export const GET_GUEST_BILLING_ADDRESS_DETAILS_SUCCESS =
  "GET_GUEST_BILLING_ADDRESS_DETAILS_SUCCESS";
export const GET_GUEST_BILLING_ADDRESS_DETAILS_FAIL =
  "GET_GUEST_BILLING_ADDRESS_DETAILS_FAIL";

export const GET_GUEST_ADDRESS_DETAILS = "GET_GUEST_ADDRESS_DETAILS";
export const GET_GUEST_ADDRESS_DETAILS_BEGIN =
  "GET_GUEST_ADDRESS_DETAILS_BEGIN";
export const GET_GUEST_ADDRESS_DETAILS_SUCCESS =
  "GET_GUEST_ADDRESS_DETAILS_SUCCESS";
export const GET_GUEST_ADDRESS_DETAILS_FAIL = "GET_GUEST_ADDRESS_DETAILS_FAIL";

// SHOP PAYMENT

export const INITIATE_GUEST_SHOP_PAYMENT = "INITIATE_GUEST_SHOP_PAYMENT";
export const INITIATE_GUEST_SHOP_PAYMENT_BEGIN =
  "INITIATE_GUEST_SHOP_PAYMENT_BEGIN";
export const INITIATE_GUEST_SHOP_PAYMENT_SUCCESS =
  "INITIATE_GUEST_SHOP_PAYMENT_SUCCESS";
export const INITIATE_GUEST_SHOP_PAYMENT_FAIL =
  "INITIATE_GUEST_SHOP_PAYMENT_FAIL";

export const ADD_GUEST_SHOP_PAYMENT = "ADD_GUEST_SHOP_PAYMENT";
export const ADD_GUEST_SHOP_PAYMENT_BEGIN = "ADD_GUEST_SHOP_PAYMENT_BEGIN";
export const ADD_GUEST_SHOP_PAYMENT_SUCCESS = "ADD_GUEST_SHOP_PAYMENT_SUCCESS";
export const ADD_GUEST_SHOP_PAYMENT_FAIL = "ADD_GUEST_SHOP_PAYMENT_FAIL";

// CLINIC PAYMENT

export const INITIATE_GUEST_CLINIC_PAYMENT = "INITIATE_GUEST_CLINIC_PAYMENT";
export const INITIATE_GUEST_CLINIC_PAYMENT_BEGIN =
  "INITIATE_GUEST_CLINIC_PAYMENT_BEGIN";
export const INITIATE_GUEST_CLINIC_PAYMENT_SUCCESS =
  "INITIATE_GUEST_CLINIC_PAYMENT_SUCCESS";
export const INITIATE_GUEST_CLINIC_PAYMENT_FAIL =
  "INITIATE_GUEST_CLINIC_PAYMENT_FAIL";

export const ADD_GUEST_CLINIC_PAYMENT = "ADD_GUEST_CLINIC_PAYMENT";
export const ADD_GUEST_CLINIC_PAYMENT_BEGIN = "ADD_GUEST_CLINIC_PAYMENT_BEGIN";
export const ADD_GUEST_CLINIC_PAYMENT_SUCCESS =
  "ADD_GUEST_CLINIC_PAYMENT_SUCCESS";
export const ADD_GUEST_CLINIC_PAYMENT_FAIL = "ADD_GUEST_CLINIC_PAYMENT_FAIL";

export const GET_GUEST_CLINIC_PAYMENT = "GET_GUEST_CLINIC_PAYMENT";
export const GET_GUEST_CLINIC_PAYMENT_BEGIN = "GET_GUEST_CLINIC_PAYMENT_BEGIN";
export const GET_GUEST_CLINIC_PAYMENT_SUCCESS =
  "GET_GUEST_CLINIC_PAYMENT_SUCCESS";
export const GET_GUEST_CLINIC_PAYMENT_FAIL = "GET_GUEST_CLINIC_PAYMENT_FAIL";

export const GUEST_RESET_ERRORS = "GUEST_RESET_ERRORS";

// Invoice

export const GET_GUEST_INVOICE = "GET_GUEST_INVOICE";
export const GET_GUEST_INVOICE_BEGIN = "GET_GUEST_INVOICE_BEGIN";
export const GET_GUEST_INVOICE_SUCCESS = "GET_GUEST_INVOICE_SUCCESS";
export const GET_GUEST_INVOICE_FAIL = "GET_GUEST_INVOICE_FAIL";
