export const EDIT_PET_DETAILS = "EDIT_PET_DETAILS";
export const EDIT_PET_DETAILS_BEGIN = "EDIT_PET_DETAILS_BEGIN";
export const EDIT_PET_DETAILS_SUCCESS = "EDIT_PET_DETAILS_SUCCESS";
export const EDIT_PET_DETAILS_FAIL = "EDIT_PET_DETAILS_FAIL";

export const ADD_PET_DETAILS = "ADD_PET_DETAILS";
export const ADD_PET_DETAILS_BEGIN = "ADD_PET_DETAILS_BEGIN";
export const ADD_PET_DETAILS_SUCCESS = "ADD_PET_DETAILS_SUCCESS";
export const ADD_PET_DETAILS_FAIL = "ADD_PET_DETAILS_FAIL";

export const DELETE_PET_DETAILS = "DELETE_PET_DETAILS";
export const DELETE_PET_DETAILS_BEGIN = "DELETE_PET_DETAILS_BEGIN";
export const DELETE_PET_DETAILS_SUCCESS = "DELETE_PET_DETAILS_SUCCESS";
export const DELETE_PET_DETAILS_FAIL = "DELETE_PET_DETAILS_FAIL";

export const GET_PET_DETAILS = "GET_PET_DETAILS";
export const GET_PET_DETAILS_BEGIN = "GET_PET_DETAILS_BEGIN";
export const GET_PET_DETAILS_SUCCESS = "GET_PET_DETAILS_SUCCESS";
export const GET_PET_DETAILS_FAIL = "GET_PET_DETAILS_FAIL";

export const GET_PET_BY_ID = "GET_PET_BY_ID";
export const GET_PET_BY_ID_BEGIN = "GET_PET_BY_ID_BEGIN";
export const GET_PET_BY_ID_SUCCESS = "GET_PET_BY_ID_SUCCESS";
export const GET_PET_BY_ID_FAIL = "GET_PET_BY_ID_FAIL";
